export const SocialIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_16"
            data-name="Retângulo 16"
            width="24"
            height="24"
            transform="translate(0 -0.112)"
            fill="#738e8a"
          />
        </clipPath>
      </defs>
      <g id="Grupo_22" data-name="Grupo 22" transform="translate(0 0.112)">
        <g id="Grupo_21" data-name="Grupo 21" clip-path="url(#clip-path)">
          <path
            id="Caminho_68"
            data-name="Caminho 68"
            d="M11.944,23.888A11.944,11.944,0,1,1,23.888,11.944,11.957,11.957,0,0,1,11.944,23.888m0-19.66a7.716,7.716,0,1,0,7.716,7.716,7.725,7.725,0,0,0-7.716-7.716"
            fill="#738e8a"
          />
        </g>
      </g>
    </svg>
  );
};
