export const InformativesIcon = () => {
  return (
    <svg
      id="Componente_12_1"
      data-name="Componente 12 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_13"
            data-name="Retângulo 13"
            width="23"
            height="23"
            fill="#738e8a"
          />
        </clipPath>
      </defs>
      <g id="Grupo_15" data-name="Grupo 15" clip-path="url(#clip-path)">
        <path
          id="Caminho_62"
          data-name="Caminho 62"
          d="M18.363,0H4.565A4.565,4.565,0,0,0,0,4.565v13.8a4.565,4.565,0,0,0,4.565,4.565h13.8a4.565,4.565,0,0,0,4.565-4.565V4.565A4.565,4.565,0,0,0,18.363,0m1.47,5.858A2.153,2.153,0,0,1,17.68,8.012H5.249a2.153,2.153,0,0,1,0-4.307H17.68A2.153,2.153,0,0,1,19.833,5.858Z"
          transform="translate(0 0.071)"
          fill="#738e8a"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
};
