export const HighlightsIcon = () => {
  return (
    <svg
      id="Componente_13_1"
      data-name="Componente 13 – 1"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_12"
            data-name="Retângulo 12"
            width="22"
            height="22"
            transform="translate(-0.081 -0.064)"
            fill="#738E8A"
          />
        </clipPath>
      </defs>
      <g
        id="Grupo_13"
        data-name="Grupo 13"
        transform="translate(0.081 0.064)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Caminho_61"
          data-name="Caminho 61"
          d="M13.08,0H9.32A9.32,9.32,0,0,0,0,9.32v8.991A4.089,4.089,0,0,0,4.089,22.4H18.311A4.089,4.089,0,0,0,22.4,18.311V9.32A9.32,9.32,0,0,0,13.08,0M11.2,11.841a3.266,3.266,0,1,1,3.266-3.266A3.266,3.266,0,0,1,11.2,11.841"
          transform="translate(0 -0.144)"
          fill="#738E8A"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
};
