export const LinksIcon = () => {
  return (
    <svg
      id="Componente_15_10"
      data-name="Componente 15 – 10"
      xmlns="http://www.w3.org/2000/svg"
      width="25.339"
      height="23.889"
      viewBox="0 0 25.339 23.889"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_15"
            data-name="Retângulo 15"
            width="25.339"
            height="23.889"
            transform="translate(0 0)"
            fill="#738e8a"
          />
        </clipPath>
      </defs>
      <g id="Grupo_19" data-name="Grupo 19" clip-path="url(#clip-path)">
        <path
          id="Caminho_65"
          data-name="Caminho 65"
          d="M25.339,3.744V2.5a2.5,2.5,0,0,0-2.5-2.5H2.5A2.5,2.5,0,0,0,0,2.5V3.744a2.5,2.5,0,0,0,2.5,2.5H22.839a2.5,2.5,0,0,0,2.5-2.5"
          transform="translate(0 0)"
          fill="#738e8a"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_66"
          data-name="Caminho 66"
          d="M25.339,33.644V32.4a2.5,2.5,0,0,0-2.5-2.5H2.5A2.5,2.5,0,0,0,0,32.4v1.244a2.5,2.5,0,0,0,2.5,2.5H22.839a2.5,2.5,0,0,0,2.5-2.5"
          transform="translate(0 -21.077)"
          fill="#738e8a"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_67"
          data-name="Caminho 67"
          d="M25.339,63.545V62.3a2.5,2.5,0,0,0-2.5-2.5H2.5A2.5,2.5,0,0,0,0,62.3v1.244a2.5,2.5,0,0,0,2.5,2.5H22.839a2.5,2.5,0,0,0,2.5-2.5"
          transform="translate(0 -42.156)"
          fill="#738e8a"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
};
