import axios from "axios";

let isRefreshing = false;
let failedRequestQueue = [];

const api = axios.create({
  baseURL: "https://painel.portalgmad.com.br/wp-json/wp/v2",
});

export default api;
