import { createContext, useContext, useState, useEffect } from "react";

import api from "../services/api";

//loader
import { Oval } from "react-loader-spinner";

//notification
import { notification } from "antd";

const EventsContext = createContext({});

const EventsProvider = ({ children }) => {
  //Satrred Events logic
  const [events, setEvents] = useState();
  const [eventsPage, setEventsPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalEvents, setTotalEvents] = useState(0);
  const [calendarActive, setCalendarActive] = useState(false);

  //Modal config
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (state) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //

  useEffect(() => {
    if (!calendarActive) {
      let date = new Date();
      let primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
      let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      console.log(primeiroDia.toLocaleDateString("pt-br"), ultimoDia);
      try {
        api
          .get(
            `/starred-events?page=${eventsPage + 1
            }&per_page=3&fday=${primeiroDia.toLocaleDateString(
              "pt-br"
            )}&lday=${ultimoDia.toLocaleDateString("pt-br")}`, {
            "Access-Control-Allow-Origin": "*",
            "content-type": "application/json; charset=UTF-8"
          }
          )
          .then((response) => {
            setEvents(response.data);
          });

        api
          .get(
            `/total-events?fday=${primeiroDia.toLocaleDateString(
              "pt-br"
            )}&lday=${ultimoDia.toLocaleDateString("pt-br")}`
          )
          .then((response) => {
            setTotalEvents(response.data);
          });

        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  }, [eventsPage]);

  const FetchEvents = async (fday, lday) => {
    setLoading(true);

    setCalendarActive(true);

    try {
      notification.open({
        message: `Carregando eventos...`,
        duration: 2,
      });
      const response = await api
        .get(
          `/starred-events?fday=${fday}&lday=${lday}&page=${eventsPage + 1
          }&per_page=3`
        )
        .then((response) => {
          setEvents(response.data);
        });

      await api
        .get(`/total-events?fday=${fday}&lday=${lday}`)
        .then((response) => {
          setTotalEvents(response.data);
        });

      // console.log(response);

      // if (!!response && response.response.status === 400) {
      //   notification.open({
      //     message: "Não existem eventos nas datas desejadas.",
      //     description: "Por favor, selecione novas datas...",
      //   });
      // }

      setLoading(false);
    } catch (e) {
      // notification.open({
      //   message: "Não existem eventos nas datas desejadas.",
      //   description: "Por favor, selecione novas datas",
      // });
      console.log(e);
    }
  };

  // useEffect(() => {
  //   console.log(events);
  //   console.log(totalEvents);
  // }, [events]);

  return (
    <EventsContext.Provider
      value={{
        events,
        eventsPage,
        totalEvents,
        setEventsPage,
        FetchEvents,
        loading,
        showModal,
        handleOk,
        handleCancel,
        isModalVisible,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

function useEvents() {
  const context = useContext(EventsContext);

  if (!context) {
    throw new Error("useEvents must be used within an AuthProvider");
  }

  return context;
}

export { EventsProvider, useEvents };
