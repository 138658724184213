import React from "react";

import { AuthProvider } from "./auth";
import { EventsProvider } from "./events";

const AppProvider = ({ children }) => (
  <AuthProvider>
    <EventsProvider>{children}</EventsProvider>
  </AuthProvider>
);

export default AppProvider;
