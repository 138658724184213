//images
import Banner from "../../assets/images/banner_search.png";
import SeachIcon from "../../assets/icons/search-icon.svg";

//components
import { Input } from "../../components/forms/Input";

//Form
import { useForm } from "react-hook-form";

export const Search = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async ({ search }) => {
    try {
      console.log(search);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="tw-w-[95%] tw-flex tw-flex-col tw-items-end tw-justify-center tw-pt-[20px]">
      <div className="tw-relative tw-z-[0] tw-w-full tw-h-[110px]">
        <img
          src={Banner}
          alt="Banner search"
          className="tw-absolute tw-w-full tw-h-[110px] tw-object-cover tw-rounded-[60px] tw-top-0 tw-left-0 tw-z-[0]"
        />
      </div>
      {/* <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-z-[50] tw-w-[33%] tw-h-[140px] tw-flex tw-items-center"
      >
        <Input.Search
          placeholder="Pesquisa na área do cliente"
          name="search"
          type="text"
          register={register}
          leftElement={<img src={SeachIcon} alt="Icon search" />}
        />
      </form> */}
    </div>
  );
};

export default Search;
