import { useState } from "react";

export function Button({
  backgroundColor,
  textColor,
  type,
  content,
  rightElement,
  leftElement,
  ...props
}) {
  const [style, setStyle] = useState({
    backgroundColor: backgroundColor,
    color: textColor,
  });

  function handleStyle() {
    setStyle({
      border: `1px solid ${backgroundColor}`,
      color: backgroundColor,
      backgroundColor: "transparent",
    });
  }

  function handleInitStyle() {
    setStyle({
      backgroundColor: backgroundColor,
      color: textColor,
    });
  }

  return (
    <button
      className={`tw-w-[190px] tw-h-[70px] tw-rounded-[45px] tw-flex tw-items-center tw-justify-center desktop:tw-h-[50px] desktop:tw-w-[170px]`}
      type={type}
      {...props}
      style={style}
      onMouseEnter={handleStyle}
      onMouseLeave={handleInitStyle}
      id="btn-component"
    >
      {!!leftElement && (
        <div id="site-icon">
          <leftElement />
        </div>
      )}
      {content}
      {!!rightElement && (
        <div id="site-icon">
          <rightElement />
        </div>
      )}
    </button>
  );
}
