import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from "react-query";

import "react-nice-dates/build/style.css";
//pagination
import { Pagination } from "react-headless-pagination";

import parser from "html-react-parser";

//icons
import NextIcon from "../../assets/icons/next-page.svg";

//api
import api from "../../services/api";

export const Sidebar = ({ displayInfos }) => {
  //Page States
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    console.log(page + 1);
  };

  const { data, refetch } = useQuery(
    "calendario",
    () => {
      const response = api.get(`/main-events?page=${page + 1}&per_page=8`);
      api.get(`/total-main-events`).then((response) => {
        setTotalPages(response);
      });

      return response;
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  //   console.log(data);

  return (
    <div
      id="calendar-wrapper"
      className={`tw-bg-white tw-py-[20px] tw-flex tw-px-[40px] tw-rounded-[30px] desktop:tw-px-[20px] desktop:tw-py-[15px]   ${"tw-h-[75vh] desktop:tw-h-[73vh] mobile:tw-hidden"}`}
    >
      {/* Estilizando calendário */}
      <style jsx>{`
        .-selected-start .nice-dates-day:before {
          background: white;
          border-left: 1px solid #e45737 !important;
        }

        .nice-dates-day {
          font-size: 12px;
        }

        @media (max-width: 1500px) {
          .nice-dates-day {
            font-size: 10px;
          }

          .nice-dates-week-header_day {
            font-size: 11px;
          }

          .nice-dates-navigation_current {
            font-size: 14px;
          }

          .nice-dates-navigation_next:before,
          .nice-dates-navigation_previous:before {
            height: 10px;
            width: 10px;
          }

          #calendar-col-wrapper .nice-dates-day {
            height: 24px !important;
          }
          #calendar-col-wrapper .nice-dates-grid {
            height: 150px !important;
          }
          #calendar-row-wrapper .nice-dates-day {
            height: 44px !important;
            width: 44px !important;
          }
        }

        .nice-dates-day .-selected .-selected-end:before {
          background: white;
          border-right: 1px solid #e45737 !important;
        }

        .-selected-middle .nice-dates-day_date {
          color: #e45737 !important;
        }
        .nice-dates-day:before {
          background: white;
          border-top: 1px solid #e45737;
          border-bottom: 1px solid #e45737;
        }

        .-selected-start .nice-dates-day_date {
          background: #e45737;
          border-radius: 50%;
          height: 80%;
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .-selected-end .nice-dates-day_date {
          background: #e45737;
          border-radius: 50%;
          height: 80%;
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .nice-dates-day:after {
          border: 2px solid #e45737;
        }

        .nice-dates-day:hover span {
          color: #e45737;
        }

        .-selected-end:hover span {
          color: white;
        }

        .-selected-start:hover span {
          color: white;
        }

        .nice-dates-day_month {
          display: none !important;
        }
      `}</style>
      {/* Fim da estilização */}

      <div className="tw-w-full tw-flex tw-flex-col tw-items-end">
        <div className={`${"tw-w-full"}`}>
          <div id={`${"calendar-col-wrapper"}`}></div>

          <div className={`${" tw-mt-[15px] desktop:tw-mt-[8px]"}`}>
            <div className="tw-flex tw-justify-between">
              <div>
                <p
                  className={`tw-text-textGreen tw-mb-0 ${"desktop:tw-text-[14px] "}`}
                >
                  <span
                    className={`tw-text-darkGreen tw-font-bold tw-mr-[6px] `}
                  >
                    Principais
                  </span>
                  eventos
                </p>
              </div>
              <Pagination
                currentPage={page}
                setCurrentPage={handlePageChange}
                totalPages={
                  totalPages !== null ? totalPages.data.total_pages : 10
                }
                edgePageCount={1}
                middlePagesSiblingCount={1}
                className="tw-flex tw-justify-center"
                truncableText="..."
                truncableClassName=""
              >
                <Pagination.PrevButton
                  className="tw-transition-300 hover:tw-rotate-[30deg] tw-mx-[5px]"
                // onClick={refetch}
                >
                  <img
                    src={NextIcon}
                    alt="Prev page button"
                    className="tw-rotate-[180deg] "
                  />
                </Pagination.PrevButton>

                <Pagination.NextButton
                  className="tw-transition-300 hover:tw-rotate-[30deg] tw-mx-[5px]"
                // onClick={refetch}
                >
                  <img src={NextIcon} alt="Next page button" />
                </Pagination.NextButton>

                {/* <div className="tw-flex tw-items-center tw-justify-center tw-flex-grow">
                  <Pagination.PageButton
                    activeClassName="page-active"
                    inactiveClassName="page-post"
                    className="tw-mx-[8px] tw-cursor-pointer tw-font-light tw-text-[13px]"
                  />
                </div> */}
              </Pagination>
            </div>

            <Fragment>
              <hr className="tw-my-[10px] notebook:tw-my-[7px]" />
              <div className="tw-flex tw-flex-col">
                {!!data &&
                  data.data.map((event, i) => {
                    if (event.acf.arquivo != "") {
                      return (
                        <a
                          key={i}
                          href={event.acf.arquivo.url}
                          target="_blank"
                          className={`tw-flex tw-items-center  ${displayInfos == "column"
                            ? "tw-mb-[10px]"
                            : "tw-mb-[20px]"
                            } ${event.acf.arquivo != "" ? "" : "tw-cursor-auto"}`} rel="noreferrer"
                        >
                          <div
                            className={` tw-mr-[10px] desktop:tw-mr-[5px] ${displayInfos == "column"
                              ? "tw-h-[55px] tw-w-[55px] desktop:tw-h-[40px] desktop:tw-w-[35px]"
                              : "tw-h-[75px] tw-w-[75px]  desktop:tw-h-[50px] desktop:tw-w-[50px]"
                              }`}
                          >
                            <img
                              src={event.acf?.card?.sizes?.medium}
                              alt="Infos card image"
                              className={` tw-object-cover tw-rounded-[10px] ${displayInfos == "column"
                                ? "tw-h-[45px] tw-w-[45px] tw-object-cover desktop:tw-h-[35px] desktop:tw-w-[35px]"
                                : "tw-h-[75px] tw-w-[75px]  tw-object-cover desktop:tw-h-[50px] desktop:tw-w-[50px]"
                                }`}
                            />
                          </div>
                          <div className="tw-w-[80%]">
                            <p
                              className={`tw-text-textGreen tw-font-bold tw-mb-0  ${displayInfos == "column"
                                ? "tw-text-[12px] tw-leading-[13px] desktop:tw-text-[11px] desktop:tw-leading-[10px]"
                                : "tw-text-[18px] tw-w-[70%] tw-leading-[20px] desktop:tw-text-[16px] tw-leading-[16px]"
                                }`}
                            >
                              {parser(event.title.rendered)}
                            </p>
                            <small className="tw-text-[11px] tw-text-textGreen tw-font-light desktop:tw-text-[10px] desktop:tw-leading-[5px]">
                              {event.acf.datas}
                            </small>
                          </div>
                        </a>
                      );
                    } else {
                      return (
                        <div
                          className={`tw-flex tw-items-center  ${displayInfos == "column"
                            ? "tw-mb-[10px]"
                            : "tw-mb-[20px]"
                            } ${event.acf.arquivo != "" ? "" : "tw-cursor-auto"}`}
                          key={i}
                        >
                          <div
                            className={` tw-mr-[10px] desktop:tw-mr-[5px] ${displayInfos == "column"
                              ? "tw-h-[55px] tw-w-[55px] desktop:tw-h-[40px] desktop:tw-w-[35px]"
                              : "tw-h-[75px] tw-w-[75px]  desktop:tw-h-[50px] desktop:tw-w-[50px]"
                              }`}
                          >
                            <img
                              src={event?.acf?.card?.sizes?.medium}
                              alt="Infos card image"
                              className={` tw-object-cover tw-rounded-[10px] ${displayInfos == "column"
                                ? "tw-h-[45px] tw-w-[45px] tw-object-cover desktop:tw-h-[35px] desktop:tw-w-[35px]"
                                : "tw-h-[75px] tw-w-[75px]  tw-object-cover desktop:tw-h-[50px] desktop:tw-w-[50px]"
                                }`}
                            />
                          </div>
                          <div className="tw-w-[80%]">
                            <p
                              className={`tw-text-textGreen tw-font-bold tw-mb-0  ${displayInfos == "column"
                                ? "tw-text-[12px] tw-leading-[13px] desktop:tw-text-[11px] desktop:tw-leading-[10px]"
                                : "tw-text-[18px] tw-w-[70%] tw-leading-[20px] desktop:tw-text-[16px] tw-leading-[16px]"
                                }`}
                            >
                              {parser(event.title.rendered)}
                            </p>
                            <small className="tw-text-[11px] tw-text-textGreen tw-font-light desktop:tw-text-[10px] desktop:tw-leading-[5px]">
                              {event.acf.datas}
                            </small>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
