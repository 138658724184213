import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
//loader
import { Oval } from "react-loader-spinner";

//parse html
import parse from "html-react-parser";

//api
import api from "../services/api";

//svgs
import CloseIcon from "../assets/icons/close-icon.svg";

//components
import Navigation from "../components/ui/navigation";
import Search from "../components/ui/search";
import Calendar from "../components/ui/calendar";
import ModalEvents from "../components/ui/Modal";
import Sidebar from "../components/ui/sidebar";

export function SingleHighlight() {
  const history = useHistory();
  const { single } = useParams();
  const { isLoading, data } = useQuery("single-highlight", () => {
    return api.get(`/destaques?slug=${single}`);
  });

  console.log(data);
  return (
    <main className="tw-w-full tw-h-[100vh] tw-flex">
      <ModalEvents />
      <Navigation active={"highlights"} />
      <section
        id="single-highlight"
        className="tw-w-[85%] te-h-[100vh] tw-bg-gray tw-rounded-[60px] tw-flex tw-flex-col tw-items-center mobile:tw-w-full"
      >
        <Search />
        {isLoading ? (
          <div className="tw-h-[100vh] tw-w-full tw-flex tw-justify-center tw-items-center">
            <Oval height="60" width="60" radius="9" color="orange" />
          </div>
        ) : (
          <div className="tw-w-[95%] tw-flex tw-justify-between tw-h-[80vh] mobile:tw-w-full mobile:tw-justify-center">
            <div className="tw-mt-[30px] tw-w-[75%] tw-h-[80vh] mobile:tw-w-[95%]">
              <div className="tw-relative">
                <img
                  src={
                    !!data &&
                    data?.data[0].acf.banner &&
                    data?.data[0].acf.banner.sizes.large
                  }
                  alt="Banner single highlight"
                  className="tw-rounded-[40px] tw-object-cover
                  tw-h-[150px] tw-w-full mobile:tw-w-full"
                />
                <div id="img-transition">
                  <img
                    src={CloseIcon}
                    alt="icon to close single"
                    className="tw-absolute tw-right-[40px] tw-top-[43%] tw-cursor-pointer"
                    onClick={() => history.push(`/highlights`)}
                  />
                </div>
              </div>
              <div
                className="tw-mt-[30px] tw-w-[98%] tw-px-[10px] tw-overflow-y-auto tw-h-[50vh] notebook:tw-h-[43vh]"
                id="single-text"
              >
                {!!data && parse(data?.data[0].content.rendered)}
                {!!data && data?.data[0].acf.link != "" && (
                  <div className="tw-w-full tw-flex tw-justify-center tw-mt-[40px]">
                    <a
                      href={data?.data[0].acf.link}
                      target="_blank"
                      className="tw-bg-textGreen tw-text-[18px] tw-font-[300] tw-text-white tw-rounded-[20px] tw-w-[180px] tw-h-[55px] tw-flex tw-justify-center tw-items-center  hover:tw-bg-white hover:tw-text-textGreen hover:tw-border-textGreen hover:tw-border-[1px]"
                    >
                      Acessar
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="tw-w-[23%] tw-h-[80vh] tw-my-[30px] mobile:tw-hidden">
              <Sidebar displayInfos="column" />
            </div>
          </div>
        )}
      </section>
    </main>
  );
}
