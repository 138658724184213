//loader
import { Oval } from "react-loader-spinner";

//Auth Context
import { useAuth } from "../context/auth";

//Form
import { useForm } from "react-hook-form";

//yup
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Components
import { Input } from "../components/forms/Input";
import { Button } from "../components/forms/Button";

//images
import BannerLogin from "../assets/images/banner_login.png";
import DetailsLogin from "../assets/images/details_login.png";
import Logo from "../assets/images/logo.svg";
import PeopleLogin from "../assets/images/people_login.png";

// Form schema
const schema = yup
  .object({
    username: yup.string().required("Campo usuário é obrigatório"),
    password: yup.string().required("Campo senha é obrigatório"),
  })
  .required();

export function Login() {
  //Form declarations
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const { signIn, loading } = useAuth();

  const onSubmit = async ({ username, password }) => {
    try {
      signIn({ username, password });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <section className="tw-relative tw-w-full tw-h-[100vh]" id="login_section">
      <div className="tw-absolute tw-top-0 tw-w-full tw-z-[10]">
        <img
          src={DetailsLogin}
          className="tw-z-[10] tw-h-[100vh] tw-w-[30%] mobile:tw-w-[50%] mobile:tw-object-cover"
          alt="Details image"
        />
      </div>
      <div className="tw-absolute tw-top-0 tw-w-full tw-z-[-1]">
        <img
          src={BannerLogin}
          className="tw-w-full tw-h-[100vh] tw-object-cover tw-z-[-1]"
          alt="Banner"
        />
      </div>
      <div className="tw-w-full tw-z-[50] tw-flex tw-items-center tw-justify-center tw-px-[10%] tw-h-[100vh] mobile:tw-flex-col">
        <div className="tw-z-[50]">
          <img
            src={PeopleLogin}
            className="tw-h-[700px] desktop:tw-h-[500px] mobile:tw-h-[200px]"
            alt="Client Gmad"
          />
        </div>
        <form
          className="tw-z-[50] mobile:tw-flex mobile:tw-items-center mobile:tw-flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <img
            src={Logo}
            className="tw-mb-[40px] mobile:tw-w-[100px]"
            alt="Logo"
          />
          <div>
            <h3 className="tw-text-white tw-text-[50px] tw-mb-0 tw-leading-[50px] tw-font-light desktop:tw-text-[30px] desktop:tw-leading-[30px]">
              Bem vindo(a) ao portal
            </h3>

            <h1 className="tw-text-white tw-text-[80px] tw-leading-[80px] desktop:tw-text-[50px] desktop:tw-leading-[50px]">
              Portal GMAD
            </h1>
            <div className="tw-mt-[40px]">
              <Input
                placeholder="Digite o seu login"
                name="username"
                type="text"
                required
                isErrored={!!errors.username}
                errorMessage={errors.username?.message}
                register={register}
              />
              <Input.Password
                placeholder="Senha"
                name="password"
                required
                isErrored={!!errors.password}
                errorMessage={errors.password?.message}
                register={register}
              />
              <div className="tw-flex tw-justify-end">
                <Button
                  type="submit"
                  content={
                    loading ? (
                      <Oval height="20" width="20" radius="9" color="orange" />
                    ) : (
                      "Enviar"
                    )
                  }
                  backgroundColor="white"
                  textColor="#E45737"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
