import { useState } from "react";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

//Using input default like wrapper
import { Input } from "./Input";

export function Password({ ...props }) {
  //Password state
  const [isVisible, setVisible] = useState(false);

  function handleVisibility() {
    setVisible(!isVisible);
  }

  function EyeElement() {
    if (isVisible) {
      return (
        <AiOutlineEye
          onClick={handleVisibility}
          className="site-icon"
          alt="Password visible"
        />
      );
    } else {
      return (
        <AiOutlineEyeInvisible
          onClick={handleVisibility}
          id="site-icon"
          alt="Password not visible"
        />
      );
    }
  }

  return (
    <Input
      type={isVisible ? "text" : "password"}
      rightElement={<EyeElement />}
      {...props}
    />
  );
}
