import { useState } from "react";

//icons
import CheckIcon from "../../../assets/icons/check-icon.svg";

export function File({
  name,
  placeholder,
  register,
  isErrored,
  errorMessage,
  ...props
}) {
  const [fileName, setFileName] = useState(null);

  const handleFile = (e) => {
    setFileName(e.target.files[0].name);
  };

  return (
    <div className="tw-my-[20px] tw-flex tw-justify-between tw-items-center">
      <label
        htmlFor={name}
        className="tw-border-[1px] tw-rounded-[35px] tw-border-orange tw-px-[80px] tw-py-[20px] tw-text-orange tw-cursor-pointer tw-transition-300 hover:tw-bg-orange hover:tw-text-white"
      >
        {placeholder}
      </label>

      <input
        type="file"
        name={name}
        id={name}
        onChange={(e) => handleFile(e)}
        style={{ display: "hidden" }}
        className="tw-border-none tw-hidden"
        {...register(name)}
        {...props}
      />
      {fileName !== null ? (
        <div className="tw-flex tw-border-[1px] tw-py-[10px] tw-px-[30px] tw-rounded-[30px] tw-border-orange tw-w-[50%] tw-items-center tw-justify-between">
          {fileName}
          <div className="tw-bg-orange tw-flex tw-justify-center tw-h-[40px] tw-rounded-[50%] tw-p-[10px] tw-w-[40px]">
            <img src={CheckIcon} alt="check file icon" />
          </div>
        </div>
      ) : (
        ""
      )}
      {isErrored && <span className="tw-text-red">{errorMessage}</span>}
    </div>
  );
}
