import { Switch } from "react-router-dom";

// Pages
import { Login } from "../pages/login";
import { Highlights } from "../pages/highlights";
import { Informatives } from "../pages/informatives";
import { Catalogs } from "../pages/catalogs";
import { Links } from "../pages/links";
import { Social } from "../pages/social";
import { SingleHighlight } from "../pages/singleHighlight";
import { Files } from "../pages/files";

//Route
import Public from "./public";
import Private from "./private";

//Components
import ScrollToTop from "../components/scrollToTop";

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Public component={Login} isGuest path="/" exact />
        <Private component={Highlights} path="/highlights" exact />
        <Private component={SingleHighlight} path="/highlights/:single" exact />
        <Private component={Informatives} path="/informatives" exact />
        <Private component={Catalogs} path="/catalogs" exact />
        <Private component={Links} path="/links" exact />
        <Private component={Social} path="/social" exact />
        <Private component={Files} path="/files" exact />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
