import { Input as DefaultInput } from "./Input";
import { Password as PasswordInput } from "./Password";
import { Search as SearchInput } from "./Search";
import { File as FileInput } from "./File";

export function Input({ ...props }) {
  return <DefaultInput {...props} />;
}

Input.Password = PasswordInput;
Input.Search = SearchInput;
Input.File = FileInput;
