import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

//loader
import { Oval } from "react-loader-spinner";

//api
import api from "../services/api";

//components
import Navigation from "../components/ui/navigation";
import Search from "../components/ui/search";
import Calendar from "../components/ui/calendar";
import ModalEvents from "../components/ui/Modal";
import Sidebar from "../components/ui/sidebar";

export function Highlights() {
  //Request data
  const { isLoading, data, isError, error } = useQuery("highlights", () => {
    return api.get("/destaques");
  });

  const history = useHistory();

  return (
    <main className="tw-w-full tw-h-[100vh] tw-flex">
      <ModalEvents />
      <Navigation active={"highlights"} />
      <section
        id="highlight"
        className="tw-w-[85%] tw-bg-gray tw-rounded-[60px] tw-flex tw-flex-col tw-items-center mobile:tw-w-[100%]"
      >
        <Search />
        <div className="tw-w-[95%] tw-flex tw-justify-between tw-h-[80vh]">
          <div className="tw-w-[75%] tw-block tw-h-[80vh] mobile:tw-w-full">
            {isLoading ? (
              <div className="tw-h-[100vh] tw-w-full tw-flex tw-justify-center tw-items-center">
                <Oval height="60" width="60" radius="9" color="orange" />
              </div>
            ) : (
              <div className="tw-full tw-flex tw-flex-col tw-items-center  tw-h-[80vh] notebook:tw-h-[75vh]">
                <h1 className="tw-text-[38px] tw-text-darkGreen tw-font-light tw-my-[20px] desktop:tw-text-[30px] mobile:tw-mb-0">
                  Acessos Rápidos
                </h1>
                <div
                  id="posts-wrapper"
                  className="tw-grid tw-grid-cols-3 tw-h-[70vh] tw-mt-[10px] tw-px-[10px] notebook:tw-h-[75vh] tw-overflow-y-auto mobile:tw-grid-cols-2 desktop:tw-py-[10px] "
                >
                  {data?.data.map((post, i) => (
                    <div
                      className="tw-flex tw-flex-col tw-items-center tw-h-[450px] tw-w-full desktop:tw-h-[360px] mobile:tw-h-[400px]"
                      key={i}
                    >
                      <div
                        onClick={() => history.push(`highlights/${post.slug}`)}
                        className="tw-flex tw-justify-center tw-w-full tw-px-[10%]"
                      >
                        <img
                          src={!!post.acf.card && post.acf.card.sizes.large}
                          alt="Highlight card image"
                          id="card-image"
                          className="tw-rounded-[40px] tw-w-[100%] tw-object-cover tw-cursor-pointer tw-h-[250px] desktop:tw-h-[250px] notebook:tw-h-[200px] mobile:tw-h-[230px]"
                        />
                      </div>
                      <p className="tw-w-[70%] tw-text-center tw-text-darkGreen tw-my-[16px] notebook:tw-text-[12px] notebook:tw-leading-[12px]">
                        {post.title.rendered}
                      </p>
                      <div>
                        <button
                          onClick={() =>
                            history.push(`highlights/${post.slug}`)
                          }
                          className="tw-w-[190px] tw-h-[55px] tw-rounded-[45px] tw-cursor-pointer tw-my-[5px] tw-flex tw-items-center tw-justify-center tw-bg-orange tw-text-white tw-transition-300 hover:tw-bg-darkGreen desktop:tw-w-[150px] desktop:tw-h-[46px] notebook:tw-h-[42px] desktop:tw-text-[12px]"
                        >
                          Saiba mais
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="tw-w-[23%] tw-h-[80vh] tw-my-[30px] desktop:tw-my-[15px] desktop:tw-h-[75vh] mobile:tw-hidden">
            <Sidebar displayInfos="column" />
          </div>
        </div>
      </section>
    </main>
  );
}
