import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";

import "./dist/output.css";

//antd css
import "antd/dist/antd.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
