export const FolderIcon = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 489 489"
      // style="enable-background:new 0 0 489 489;"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M488.485,433.9V54.1c0-30.2-23.9-54.1-54.1-54.1h-379.8c-30.2,0-54.1,23.9-54.1,54.1v380.8c0,30.2,23.9,54.1,54.1,54.1
			h380.8C465.585,489,489.485,465.1,488.485,433.9z M447.885,433.9c0,7.3-6.2,13.5-13.5,13.5h-379.8c-7.3,0-13.5-6.2-13.5-13.5V54.1
			c0-7.3,6.2-13.5,13.5-13.5h379.7c7.3,0,13.5,6.2,13.5,13.5v379.8H447.885z"
            fill="#738e8a"
          />
          <path
            d="M361.485,100.9h-233c-11.4,0-20.8,9.4-20.8,20.8s9.4,20.8,20.8,20.8h233c11.4,0,20.8-9.4,20.8-20.8
			S372.985,100.9,361.485,100.9z"
            fill="#738e8a"
          />
          <path
            d="M361.485,223.7h-233c-11.4,0-20.8,9.4-20.8,20.8s9.4,20.8,20.8,20.8h233c11.4,0,20.8-9.4,20.8-20.8
			C382.285,233.1,372.985,223.7,361.485,223.7z"
            fill="#738e8a"
          />
          <path
            d="M360.785,344.7h-233c-11.4,0-20.8,9.4-20.8,20.8s9.4,20.8,20.8,20.8h233c11.4,0,20.8-9.4,20.8-20.8
			S372.285,344.7,360.785,344.7z"
            fill="#738e8a"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
