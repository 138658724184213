//Using gloabal states
import { createContext, useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

// Services
import tokenApi from "../services/token";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  // State for user data
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("@Portal-gmad:user")) || null
  );
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("@Portal-gmad:user_email")) || null
  );
  const [token, setToken] = useState(
    localStorage.getItem("@Portal-gmad:token") || null
  );

  //Loading state
  const [loading, setLoading] = useState(false);

  //Fetching login data
  const signIn = useCallback(async ({ username, password }) => {
    setLoading(true);
    console.log(username);

    try {
      const response = await tokenApi.post(
        `token?username=${username}&password=${password}`
      );

      const token = response.data.token;
      const user_name = response.data.user_display_name;
      const user_email = response.data.user_email;

      setUser(user_name);
      setEmail(JSON.stringify(user_email));

      localStorage.setItem("@Portal-gmad:token", token);
      localStorage.setItem("@Portal-gmad:user_name", user_name);
      localStorage.setItem("@Portal-gmad:user_email", JSON.stringify(email));

      //   setUser(user);
      setToken(token);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const history = useHistory();

  //Logout session
  const signOut = useCallback(() => {
    localStorage.removeItem("@Portal-gmad:token");

    setToken(null);
    setUser(null);

    history.push("login");
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
