export const MuralIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      viewBox="0 0 23 23"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_17"
            data-name="Retângulo 17"
            width="25.216"
            height="22.364"
            fill="#738e8a"
          />
        </clipPath>
      </defs>
      <g id="Grupo_24" data-name="Grupo 24" transform="translate(0 0)">
        <g
          id="Grupo_23"
          data-name="Grupo 23"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Caminho_69"
            data-name="Caminho 69"
            d="M19.877,0H5.34A5.346,5.346,0,0,0,0,5.34V17.025a5.346,5.346,0,0,0,5.34,5.34H19.877a5.346,5.346,0,0,0,5.34-5.34V5.34A5.346,5.346,0,0,0,19.877,0m1.78,17.025a1.782,1.782,0,0,1-1.78,1.78H5.34a1.782,1.782,0,0,1-1.78-1.78V5.34A1.782,1.782,0,0,1,5.34,3.56H19.877a1.782,1.782,0,0,1,1.78,1.78Z"
            transform="translate(0 0)"
            fill="#738e8a"
          />
          <path
            id="Caminho_70"
            data-name="Caminho 70"
            d="M30.138,22.471H18.258a1.78,1.78,0,1,0,0,3.56h11.88a1.78,1.78,0,0,0,0-3.56"
            transform="translate(-11.59 -15.805)"
            fill="#738e8a"
          />
        </g>
      </g>
    </svg>
  );
};
