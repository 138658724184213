//interconnecting the user interface with urls
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from 'react-gtm-module';

//a better option to fetch data
import { QueryClientProvider } from "react-query";

// Context
import AppProvider from "./context";

// Services
import { queryClient } from "./services/queryClient";

// Routes
import Routes from "./Routes";

const tagManagerArgs = {
  gtmId: 'GTM-W5SFZDX7'
}

TagManager.initialize(tagManagerArgs)

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>
    </QueryClientProvider>
  );
}
