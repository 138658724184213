import { useRef } from "react";

export function Search({
  name,
  register,
  required,

  leftElement,
  ...props
}) {
  const inputRef = useRef(null);

  //focus
  const setInputFocus = (e) => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  };

  return (
    <div className="tw-flex tw-items-center tw-z-[100] tw-w-[80%] tw-rounded-[30px] tw-bg-white">
      {!!leftElement && (
        <div
          id="site-icon"
          className="tw-cursor-pointer tw-flex tw-justify-center tw-w-[20%]"
        >
          {leftElement}
        </div>
      )}
      <input
        ref={inputRef}
        id={`${name}_login`}
        className="tw-w-full tw-z-[100] tw-py-[20px] tw-pr-[40px] tw-pl-[10px] tw-rounded-[50px] tw-text-textGreen tw-placeholder-textGreen tw-border-none focus:tw-border-0"
        tabIndex="-1"
        onClick={(e) => setInputFocus(e)}
        {...register(name)}
        {...props}
      />
    </div>
  );
}
