import { useRef } from "react";

import { Base } from "./Base";

export function Input({
  name,
  register,
  required,
  isErrored,
  errorMessage,
  rightElement,
  leftElement,
  ...props
}) {
  const inputRef = useRef(null);

  //focus
  const setInputFocus = (e) => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  };

  return (
    <Base>
      <div className="tw-flex tw-items-center">
        {!!leftElement && (
          <div
            id="site-icon"
            className="tw-cursor-pointer tw-flex tw-justify-center tw-w-[20%]"
          >
            {leftElement}
          </div>
        )}
        <input
          ref={inputRef}
          id={`${name}_login`}
          className="tw-w-full tw-py-[20px] tw-px-[40px] tw-rounded-[50px] tw-text-white tw-bg-transparent tw-placeholder-white tw-border-none focus:tw-border-0 desktop:tw-py-[14px]"
          tabIndex="-1"
          onClick={(e) => setInputFocus(e)}
          {...register(name, { required })}
          {...props}
        />
        {!!rightElement && (
          <div
            id="site-icon"
            className="tw-cursor-pointer tw-flex tw-justify-center tw-w-[20%]"
          >
            {rightElement}
          </div>
        )}
      </div>
    </Base>
  );
}
