import React, { useState, useEffect } from "react";

import { useQuery } from "react-query";

//components
import Navigation from "../components/ui/navigation";
import Search from "../components/ui/search";
import Calendar from "../components/ui/calendar";
import ModalEvents from "../components/ui/Modal";
import Sidebar from "../components/ui/sidebar";

//pagination
import { Pagination } from "react-headless-pagination";
//loader
import { Oval } from "react-loader-spinner";

//icons
import NextIcon from "../assets/icons/next-page.svg";
//api
import api from "../services/api";

export function Informatives() {
  //Page States
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    console.log(page + 1);
  };
  //Request data
  const { isLoading, data, refetch } = useQuery(
    "informatives",
    () => {
      const response = api.get(`/informativos?page=${page + 1}&per_page=3`);
      api.get(`/total-informatives`).then((response) => {
        setTotalPages(response);
      });

      return response;
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <main className="tw-w-full tw-h-[100vh] tw-flex">
      <Navigation active={"informatives"} />
      <ModalEvents />
      <section
        id="catalogs"
        className="tw-w-[85%] tw-bg-gray tw-rounded-[60px] tw-flex tw-flex-col tw-items-center mobile:tw-w-full"
      >
        <Search />
        <div className="tw-w-[95%] tw-flex tw-justify-between tw-h-[80vh]">
          <div className="tw-flex tw-flex-col tw-w-[75%] mobile:tw-w-full">
            {isLoading ? (
              <div className="tw-h-[100vh] tw-w-full tw-flex tw-justify-center tw-items-center">
                <Oval height="60" width="60" radius="9" color="orange" />
              </div>
            ) : (
              <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-mt-[30px] tw-mb-[40px]">
                <h1 className="tw-text-darkGreen tw-text-[34px] tw-font-light">
                  Informativos
                </h1>
                <div
                  id="posts-wrapper"
                  className="tw-grid tw-grid-cols-3 tw-gap-4 tw-w-full mobile:tw-grid-cols-2"
                >
                  {data?.data.map((post, i) => (
                    <div className="tw-flex tw-flex-col tw-items-center tw-relative tw">
                      <a
                        href={post.acf.arquivo.url}
                        target="_blank"
                        className="tw-w-[85%] tw-flex tw-flex-col tw-items-center desktop:tw-w-[75%] mobile:tw-w-[90%]"
                      >
                        <img
                          src={post.acf.card.sizes.large}
                          alt="Highlight card image"
                          id="card-image"
                          className="tw-h-[260px] tw-w-[100%] tw-object-cover tw-rounded-[50px] desktop:tw-h-[210px] notebook:tw-h-[180px]"
                        />
                        <p className="tw-w-[60%] tw-font-[500] tw-my-[5px] tw-text-center tw-text-orange ">
                          {post.title.rendered}
                        </p>
                        <p className="tw-w-[60%] tw-rounded-[40px] tw-py-[10px] tw-bg-orange tw-text-center tw-text-white hover:tw-w-[65%] tw-transition-600 hover:tw-transition-300">
                          Download
                        </p>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Pagination
              currentPage={page}
              setCurrentPage={handlePageChange}
              totalPages={
                totalPages !== null ? totalPages.data.total_pages : 10
              }
              edgePageCount={1}
              middlePagesSiblingCount={1}
              className="tw-flex tw-justify-center"
              truncableText="..."
              truncableClassName=""
            >
              <Pagination.PrevButton
                className="tw-transition-300 hover:tw-rotate-[30deg] tw-mx-[5px]"
                onClick={refetch}
              >
                <img
                  src={NextIcon}
                  alt="Prev page button"
                  className="tw-rotate-[180deg]"
                />
              </Pagination.PrevButton>

              <Pagination.NextButton
                className="tw-transition-300 hover:tw-rotate-[30deg] tw-mx-[5px]"
                onClick={refetch}
              >
                <img src={NextIcon} alt="Next page button" />
              </Pagination.NextButton>

              <div className="flex items-center justify-center flex-grow">
                <Pagination.PageButton
                  activeClassName="page-active"
                  inactiveClassName="page-post"
                  className="tw-mx-[10px] tw-cursor-pointer tw-font-light tw-text-[13px]"
                />
              </div>
            </Pagination>
          </div>
          <div className="tw-w-[23%] tw-h-[80vh] tw-my-[30px] mobile:tw-hidden">
            <Sidebar displayInfos="column" />
          </div>
        </div>
      </section>
    </main>
  );
}
