import React, { useState, useEffect, Fragment } from "react";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
//pagination
import { Pagination } from "react-headless-pagination";

import parser from "html-react-parser";

//icons
import PlusIcon from "../../assets/icons/plus-icon.svg";
import MenusIcon from "../../assets/icons/menus-icon.svg";
import enUS from "date-fns/esm/locale/en-US/index.js";
//icons
import NextIcon from "../../assets/icons/next-page.svg";

//events context
import { useEvents } from "../../context/events";

export const Calendar = ({ displayInfos }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
    setEventsPage(0);
  };

  //Events context
  const {
    FetchEvents,
    loading,
    eventsPage,
    totalEvents,
    setEventsPage,
    events,
    showModal,
    handleCancel,
  } = useEvents();
  console.log(events);

  // useEffect(() => {
  //   console.log(displayInfos, totalEvents);
  // }, []);

  const handlePageChange = (page) => {
    setEventsPage(page);
  };

  //Fetching events when final date is selected
  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      //Formating dates
      const fday_formated = format(startDate, "dd/MM/yyyy", {
        locale: enUS,
      });
      const lday_formated = format(endDate, "dd/MM/yyyy", { locale: enUS });

      const collection = document.getElementsByClassName("-selected");

      // console.log(collection);

      // $(".resultado").html(conteudoArr.join(""));

      FetchEvents(fday_formated, lday_formated);
    }
  }, [endDate, eventsPage]);

  return (
    <div
      id="calendar-wrapper"
      className={`tw-bg-white tw-py-[20px] tw-flex tw-px-[40px] tw-rounded-[30px] desktop:tw-px-[20px] desktop:tw-py-[15px]   ${"desktop:tw-h-[70vh] mobile:tw-h-[75vh]"}`}
    >
      {/* Estilizando calendário */}
      <style jsx>{`
        .-selected-start .nice-dates-day:before {
          background: white;
          border-left: 1px solid #e45737 !important;
        }

        .nice-dates-day {
          font-size: 12px;
        }

        @media (max-width: 1500px) {
          .nice-dates-day {
            font-size: 10px;
          }

          .nice-dates-week-header_day {
            font-size: 11px;
          }

          .nice-dates-navigation_current {
            font-size: 14px;
          }

          .nice-dates-navigation_next:before,
          .nice-dates-navigation_previous:before {
            height: 10px;
            width: 10px;
          }

          #calendar-col-wrapper .nice-dates-day {
            height: 24px !important;
          }
          #calendar-col-wrapper .nice-dates-grid {
            height: 150px !important;
          }
          #calendar-row-wrapper .nice-dates-day {
            height: 44px !important;
            width: 44px !important;
          }
        }

        .nice-dates-day .-selected .-selected-end:before {
          background: white;
          border-right: 1px solid #e45737 !important;
        }

        .-selected-middle .nice-dates-day_date {
          color: #e45737 !important;
        }
        .nice-dates-day:before {
          background: white;
          border-top: 1px solid #e45737;
          border-bottom: 1px solid #e45737;
        }

        .-selected-start .nice-dates-day_date {
          background: #e45737;
          border-radius: 50%;
          height: 80%;
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .-selected-end .nice-dates-day_date {
          background: #e45737;
          border-radius: 50%;
          height: 80%;
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .nice-dates-day:after {
          border: 2px solid #e45737;
        }

        .nice-dates-day:hover span {
          color: #e45737;
        }

        .-selected-end:hover span {
          color: white;
        }

        .-selected-start:hover span {
          color: white;
        }

        .nice-dates-day_month {
          display: none !important;
        }

        .-selected span {
          color: #e45737 !important;
        }

        .-selected-start span,
        .-selected-end span {
          color: white !important;
        }
      `}</style>
      {/* Fim da estilização */}
      {/* <p>
        Selected start date:{" "}
        {startDate ? format(startDate, "dd/MM/yyyy", { locale: enUS }) : "none"}
        .
      </p>
      <p>
        Selected end date:{" "}
        {endDate ? format(endDate, "dd/MM/yyyy", { locale: enUS }) : "none"}.
      </p>
      <p>Currently selecting: {focus}.</p> */}

      <div className="tw-w-full tw-flex tw-flex-col tw-items-end">
        <button
          className="tw-flex tw-justify-center tw-items-center tw-mb-[15px]"
          onClick={handleCancel}
        >
          <img src={MenusIcon} alt="icon plus" className="tw-mr-[10px]" />
          <p className="tw-text-textGreen tw-mb-0">Ver menos</p>
        </button>

        <div
          className={`${"tw-flex tw-flex-row-reverse tw-w-full tw-justify-between mobile:tw-flex-col"}`}
        >
          <div
            className={`${"tw-w-[365px] tw-h-[392px] notebook:tw-w-[308px] mobile:tw-w-full"}`}
            id={`${"calendar-row-wrapper"}`}
          >
            <DateRangePickerCalendar
              startDate={startDate}
              endDate={endDate}
              focus={focus}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              onFocusChange={handleFocusChange}
              locale={pt}
            />
          </div>

          <div
            className={`${"tw-w-[50%] mobile:tw-w-full mobile:tw-mt-[20px]"}`}
          >
            <div className="tw-flex tw-justify-between">
              <div>
                <p
                  className={`tw-text-textGreen tw-mb-0 ${"tw-text-[20px] desktop:tw-text-[16px]"}`}
                >
                  <span
                    className={`tw-text-darkGreen tw-font-bold tw-mr-[6px] `}
                  >
                    Selecione as datas
                  </span>
                  desejadas para filtrar os eventos...
                </p>
              </div>
            </div>

            <Fragment>
              <hr className="tw-my-[10px] notebook:tw-my-[7px]" />
              <div className="tw-flex tw-flex-col">
                {!!events &&
                  events.map((event, i) => {
                    if (event.acf.arquivo != "") {
                      return (
                        <a
                          key={i}
                          href={event.acf.arquivo.url}
                          target="_blank"
                          className={`tw-flex tw-items-center  ${displayInfos == "column"
                            ? "tw-mb-[10px]"
                            : "tw-mb-[20px]"
                            } ${event.acf.arquivo != "" ? "" : "tw-cursor-auto"}`}
                        >
                          <div
                            className={` tw-mr-[10px] desktop:tw-mr-[5px] ${"tw-h-[75px] tw-w-[75px]  desktop:tw-h-[50px] desktop:tw-w-[50px]"}`}
                          >
                            <img
                              src={event.acf.card.sizes.medium}
                              alt="Infos card image"
                              className={` tw-object-cover tw-rounded-[10px] ${"tw-h-[75px] tw-w-[75px]  tw-object-cover desktop:tw-h-[50px] desktop:tw-w-[50px]"}`}
                            />
                          </div>
                          <div className="tw-w-[80%]">
                            <p
                              className={`tw-text-textGreen tw-font-bold tw-mb-0  ${"tw-text-[18px] tw-w-[70%] tw-leading-[20px] desktop:tw-text-[16px] tw-leading-[16px]"}`}
                            >
                              {parser(event.title.rendered)}
                            </p>
                            <small className="tw-text-[11px] tw-text-textGreen tw-font-light desktop:tw-text-[10px] desktop:tw-leading-[5px]">
                              {event.acf.datas}
                            </small>
                          </div>
                        </a>
                      );
                    } else {
                      return (
                        <div
                          className={`tw-flex tw-items-center  ${"tw-mb-[20px]"} ${event.acf.arquivo != "" ? "" : "tw-cursor-auto"
                            }`}
                        >
                          <div
                            className={` tw-mr-[10px] desktop:tw-mr-[5px] ${"tw-h-[75px] tw-w-[75px]  desktop:tw-h-[50px] desktop:tw-w-[50px]"}`}
                          >
                            <img
                              src={event.acf.card.sizes.medium}
                              alt="Infos card image"
                              className={` tw-object-cover tw-rounded-[10px] ${"tw-h-[75px] tw-w-[75px]  tw-object-cover desktop:tw-h-[50px] desktop:tw-w-[50px]"}`}
                            />
                          </div>
                          <div className="tw-w-[80%]">
                            <p
                              className={`tw-text-textGreen tw-font-bold tw-mb-0  ${"tw-text-[18px] tw-w-[70%] tw-leading-[20px] desktop:tw-text-[16px] tw-leading-[16px]"}`}
                            >
                              {parser(event.title.rendered)}
                            </p>
                            <small className="tw-text-[11px] tw-text-textGreen tw-font-light desktop:tw-text-[10px] desktop:tw-leading-[5px]">
                              {event.acf.datas}
                            </small>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
              <div className="tw-w-full tw-flex tw-justify-end tw-mt-[30px]">
                <Pagination
                  currentPage={eventsPage}
                  setCurrentPage={handlePageChange}
                  totalPages={totalEvents !== 0 ? totalEvents.total_pages : 10}
                  edgePageCount={1}
                  middlePagesSiblingCount={1}
                  className="tw-flex tw-justify-center"
                  truncableText="..."
                  truncableClassName=""
                >
                  <Pagination.PrevButton
                    className="tw-transition-300 hover:tw-rotate-[30deg] tw-mx-[5px]"
                  // onClick={refetch}
                  >
                    <img
                      src={NextIcon}
                      alt="Prev page button"
                      className="tw-rotate-[180deg]"
                    />
                  </Pagination.PrevButton>
                  <div className="tw-flex tw-items-center tw-justify-center tw-flex-grow">
                    <Pagination.PageButton
                      activeClassName="page-active"
                      inactiveClassName="page-post"
                      className="tw-mx-[8px] tw-cursor-pointer tw-font-light tw-text-[13px]"
                    />
                  </div>
                  <Pagination.NextButton
                    className="tw-transition-300 hover:tw-rotate-[30deg] tw-mx-[5px]"
                  // onClick={refetch}
                  >
                    <img src={NextIcon} alt="Next page button" />
                  </Pagination.NextButton>
                </Pagination>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
