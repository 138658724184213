export function Button({
  backgroundColor,
  textColor,
  type,
  identifier,
  content,
  rightElement,
  leftElement,
  ...props
}) {
  return (
    <button
      className={`tw-w-[220px] tw-text-[15px] tw-h-[55px] tw-rounded-[45px] tw-cursor-pointer tw-my-[15px] tw-flex tw-items-center tw-justify-center desktop:tw-w-[170px] desktop:tw-h-[48px] desktop:tw-text-[9px] desktop:tw-leading-[10px] notebook:tw-w-[150px] notebook:tw-h-[43px]`}
      type={type}
      {...props}
      id={identifier}
    >
      <div className="tw-flex tw-items-center tw-w-[160px] desktop:tw-w-[125px] notebook:tw-w-[115px]">
        {!!leftElement && (
          <div id="site-icon" className="tw-mr-[10px] tw-w-[26px] tw-h-[22px]">
            {leftElement}
          </div>
        )}
        {content}
        {!!rightElement && (
          <div id="site-icon">
            <rightElement />
          </div>
        )}
      </div>
    </button>
  );
}
