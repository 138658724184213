export const CatalogsIcon = () => {
  return (
    <svg
      id="Componente_14_1"
      data-name="Componente 14 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="25.339"
      height="23"
      viewBox="0 0 25.339 23"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_14"
            data-name="Retângulo 14"
            width="25.339"
            height="23"
            transform="translate(0 0)"
            fill="#738e8a"
          />
        </clipPath>
      </defs>
      <g id="Grupo_17" data-name="Grupo 17" clip-path="url(#clip-path)">
        <path
          id="Caminho_63"
          data-name="Caminho 63"
          d="M76.848,0H75.719A2.269,2.269,0,0,0,73.45,2.269V20.731A2.269,2.269,0,0,0,75.719,23h1.129a2.269,2.269,0,0,0,2.269-2.269V2.269A2.269,2.269,0,0,0,76.848,0"
          transform="translate(-53.778 0)"
          fill="#738e8a"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_64"
          data-name="Caminho 64"
          d="M13.232,0H3.951A3.951,3.951,0,0,0,0,3.951v15.1A3.951,3.951,0,0,0,3.951,23h9.281a3.951,3.951,0,0,0,3.951-3.951V3.951A3.951,3.951,0,0,0,13.232,0M8.592,15.464A3.667,3.667,0,1,1,12.259,11.8a3.668,3.668,0,0,1-3.668,3.668"
          transform="translate(0 0)"
          fill="#738e8a"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
};
