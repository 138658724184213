import React from "react";
import { useQuery } from "react-query";

//loader
import { Oval } from "react-loader-spinner";

//api
import api from "../services/api";

//components
import Navigation from "../components/ui/navigation";
import Search from "../components/ui/search";
import Calendar from "../components/ui/calendar";
import ModalEvents from "../components/ui/Modal";
import Sidebar from "../components/ui/sidebar";

export function Social() {
  //Fetching data
  const { isLoading, data, isError, error } = useQuery("social", () => {
    return api.get("/redes-sociais");
  });

  return (
    <main className="tw-w-full tw-h-[100vh] tw-flex">
      <Navigation active={"social"} />
      <ModalEvents />
      <section
        id="highlight"
        className="tw-w-[85%] tw-bg-gray tw-rounded-[60px] tw-flex tw-flex-col tw-items-center mobile:tw-w-[100%]"
      >
        <Search />
        <div className="tw-w-[95%] tw-flex tw-justify-between tw-h-[80vh]">
          <div className="tw-w-[75%] tw-h-[100vh] mobile:tw-w-full ">
            {isLoading ? (
              <div className="tw-h-[100vh] tw-w-full tw-flex tw-justify-center tw-items-center">
                <Oval height="60" width="60" radius="9" color="orange" />
              </div>
            ) : (
              <div className="tw-full tw-flex tw-flex-col tw-items-center tw-my-[30px] tw-h-[75vh] notebook:tw-h-[70vh] tw-pb-[50px] tw-overflow-y-auto">
                <div
                  id="posts-wrapper"
                  className="tw-grid tw-grid-cols-3 tw-gap-4 tw-w-full mobile:tw-grid-cols-2 "
                >
                  {data?.data.map((post, i) => (
                    <div className="tw-flex tw-flex-col tw-items-center tw-relative">
                      <a
                        href={post.acf.link}
                        target="_blank"
                        className="tw-w-[85%] tw-flex tw-flex-col tw-items-center" rel="noreferrer"
                      >
                        <img
                          src={post.acf.card.sizes.large}
                          alt="Highlight card image"
                          id="card-image"
                          className="tw-h-[290px] tw-w-[100%] tw-object-cover tw-rounded-[50px]  desktop:tw-h-[240px] notebook:tw-h-[210px]"
                        />
                        <p
                          id="card-text"
                          className="tw-w-[80%] tw-text-white tw-my-[10px] tw-rounded-[20px] tw-py-[5px] tw-bg-orange tw-text-center tw-text-center   notebook:tw-text-[12px]"
                        >
                          {post.title.rendered}
                        </p>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="tw-w-[23%] tw-h-[80vh] tw-my-[30px] mobile:tw-hidden">
            <Sidebar displayInfos="column" />
          </div>
        </div>
      </section>
    </main>
  );
}
