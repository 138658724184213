export const LogOutIcon = () => {
  return (
    <svg
      id="Componente_16_2"
      data-name="Componente 16 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="24.44"
      height="23.911"
      viewBox="0 0 24.44 23.911"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_19"
            data-name="Retângulo 19"
            width="24.44"
            height="23.911"
            fill="#738e8a"
          />
        </clipPath>
      </defs>
      <g id="Grupo_27" data-name="Grupo 27" clip-path="url(#clip-path)">
        <path
          id="Caminho_74"
          data-name="Caminho 74"
          d="M18.992,14.9a2.038,2.038,0,0,0-1.967,1.741A3.874,3.874,0,0,1,13.2,19.949H7.834a3.877,3.877,0,0,1-3.872-3.872V7.834A3.877,3.877,0,0,1,7.834,3.961h5.357a3.877,3.877,0,0,1,3.872,3.873,1.981,1.981,0,0,0,3.961,0A7.843,7.843,0,0,0,13.191,0H7.834A7.843,7.843,0,0,0,0,7.834v8.243a7.843,7.843,0,0,0,7.834,7.834H13.2a7.837,7.837,0,0,0,7.754-6.794,1.964,1.964,0,0,0-.461-1.553,1.994,1.994,0,0,0-1.5-.668"
          transform="translate(0 0)"
          fill="#738e8a"
        />
        <path
          id="Caminho_75"
          data-name="Caminho 75"
          d="M45.01,38.447a2.029,2.029,0,0,0-2.039-1.805H30.743A2.029,2.029,0,0,0,28.7,38.447,1.981,1.981,0,0,0,30.677,40.6H43.037a1.981,1.981,0,0,0,1.973-2.156"
          transform="translate(-20.577 -26.274)"
          fill="#738e8a"
        />
      </g>
    </svg>
  );
};
