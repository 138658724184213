import { Modal } from "antd";

import { useEvents } from "../../context/events";

//components
import Calendar from "../../components/ui/calendar";

export const ModalEvents = () => {
  const {
    FetchEvents,
    loading,
    eventsPage,
    totalEvents,
    setEventsPage,
    events,
    handleOk,
    handleCancel,
    isModalVisible,
  } = useEvents();

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="tw-relative tw-rounded-[30px]  !tw-w-[70%] mobile:!tw-w-[80%]"
    >
      <div className="tw-flex l">
        <div></div>
        <div className="tw-w-[100%] tw-my-[30px] desktop:tw-my-[0px]">
          <Calendar displayInfos="row" />
        </div>
      </div>
    </Modal>
  );
};

export default ModalEvents;
