import React, { useState, useEffect, useRef, Fragment } from "react";

import { useQuery } from "react-query";

//components
import Navigation from "../components/ui/navigation";
import Search from "../components/ui/search";
import Calendar from "../components/ui/calendar";
import ModalEvents from "../components/ui/Modal";
import Sidebar from "../components/ui/sidebar";

//loader
import { Oval } from "react-loader-spinner";

//api
import api from "../services/api";

export function Files() {
  //Request data
  const { isLoading, data, isError, error } = useQuery("arquivos-uteis", () => {
    return api.get("/arquivos-uteis");
  });

  const linkRef = useRef();

  // async function downloadFile(filePath) {
  //   await fetch(filePath)
  //     .then((res) => {
  //       return res.blob();
  //     })
  //     .then((blob) => {
  //       const href = window.URL.createObjectURL(blob);
  //       const a = this.linkRef.current;
  //       a.download = "Lebenslauf.pdf";
  //       a.href = href;
  //       a.click();
  //       a.href = "";
  //     })
  //     .catch((err) => console.error(err));
  // }

  // async function downloadFile(file) {
  //   console.log(file, "oi");
  //   try {
  //     fetch(`https://evento.gmad.com.br/api/imagem?url=${file}`, {
  //       method: "GET",
  //     }).then((response) => {
  //       console.log(response);
  //       // const blob = window.URL.createObjectURL(new Blob([response.url]));
  //       const link = document.createElement("a");
  //       link.href = response.url;
  //       link.setAttribute("download", "file.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // console.log(data);
  return (
    <main className="tw-w-full tw-h-[100vh] tw-flex">
      <Navigation active={"files"} />
      <ModalEvents />
      <section
        id="catalogs"
        className="tw-w-[85%] tw-bg-gray tw-rounded-[60px] tw-flex tw-flex-col tw-items-center mobile:tw-w-full"
      >
        <Search />
        <div className="tw-w-[95%] tw-flex tw-justify-between tw-h-[80vh]">
          <div className="tw-flex tw-flex-col tw-w-[75%] mobile:tw-w-full">
            {isLoading ? (
              <div className="tw-h-[100vh] tw-w-full tw-flex tw-justify-center tw-items-center">
                <Oval height="60" width="60" radius="9" color="orange" />
              </div>
            ) : (
              <div className="tw-full tw-flex tw-flex-col tw-items-center tw-my-[30px]">
                <div
                  id="posts-wrapper"
                  className="tw-grid tw-grid-cols-3 tw-gap-4 tw-gap-y-[20px] tw-w-full notebook:tw-gap-y-[5px] mobile:tw-grid-cols-2"
                >
                  {data?.data.map((post, i) => (
                    <div className="tw-flex tw-flex-col tw-items-center tw-relative tw">
                      <a
                        ref={linkRef}
                        className="tw-w-[85%] tw-flex tw-flex-col tw-items-center"
                        href={post.acf.link.url}
                        target="_blank"
                        // onClick={() => downloadFile(post.acf.link.link)}
                      >
                        <img
                          src={!!post.acf.card && post.acf.card.sizes.large}
                          alt="Highlight card image"
                          id="card-image"
                          className="tw-h-[240px] tw-w-[100%] tw-object-cover tw-rounded-[50px] desktop:tw-h-[200px] notebook:tw-h-[160px]"
                        />
                        <p
                          id="card-text"
                          className="tw-w-[80%] tw-text-white tw-rounded-[20px] tw-py-[5px] tw-px-[20px] tw-bg-orange tw-text-center  tw-text-center  tw-my-[10px] notebook:tw-text-[10px]"
                        >
                          {post.title.rendered}
                        </p>
                        {/* <p
                          id="card-text"
                          className="tw-text-textGreen tw-pt-[10px] tw-text-center tw-text-[12px] notebook:tw-text-[10px]"
                        >
                          {post.acf.descricao}
                        </p> */}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="tw-w-[23%] tw-h-[80vh] tw-my-[30px] mobile:tw-hidden">
            <Sidebar displayInfos="column" />
          </div>
        </div>
      </section>
    </main>
  );
}
