import React from "react";
import { useQuery } from "react-query";

//loader
import { Oval } from "react-loader-spinner";

//api
import api from "../services/api";

//components
import Navigation from "../components/ui/navigation";
import Search from "../components/ui/search";
import Calendar from "../components/ui/calendar";
import ModalEvents from "../components/ui/Modal";
import Sidebar from "../components/ui/sidebar";

export function Links() {
  //Fetching data
  const { isLoading, data, error } = useQuery("links", () => {
    return api.get("/links");
  });

  return (
    <main className="tw-w-full tw-h-[100vh] tw-flex">
      <Navigation active={"links"} />
      <ModalEvents />
      <section
        id="highlight"
        className="tw-w-[85%] tw-bg-gray tw-rounded-[60px] tw-flex tw-flex-col tw-items-center mobile:tw-w-[100%]"
      >
        <Search />
        <div className="tw-w-[95%] tw-flex tw-justify-between ">
          <div className="tw-w-[75%] tw-h-[100vh] tw-overflow-y-scroll mobile:tw-w-full">
            {isLoading ? (
              <div className="tw-h-[100vh] tw-w-full tw-flex tw-justify-center tw-items-center">
                <Oval height="60" width="60" radius="9" color="orange" />
              </div>
            ) : (
              <div className="tw-full tw-flex tw-flex-col tw-items-center tw-my-[30px]">
                <div
                  id="posts-wrapper"
                  className="tw-grid tw-grid-cols-3 tw-gap-3 tw-gap-y-[10px] tw-w-full notebook:tw-gap-y-[5px] mobile:tw-grid-cols-2"
                >
                  {data?.data.map((post, i) => (
                    <div className="tw-relative tw-flex tw-flex-col tw-items-center">
                      <a
                        href={post.acf.link}
                        className="tw-w-[85%] tw-flex tw-flex-col tw-items-center"
                      >
                        <img
                          src={!!post.acf.card && post.acf.card.sizes.large}
                          alt="Highlight card image"
                          id="card-image"
                          className="tw-h-[150px] tw-w-[80%] tw-object-cover tw-rounded-[50px] desktop:tw-h-[130px] notebook:tw-h-[120px]"
                        />
                        <p
                          id="card-text"
                          className="tw-w-[80%]  tw-rounded-[20px] tw-py-[5px] tw-bg-orange tw-text-white tw-my-[5px]  tw-mb-0 tw-text-center  notebook:tw-text-[10px]"
                        >
                          {post.title.rendered}
                        </p>
                        <p
                          id="card-text"
                          className="tw-text-textGreen tw-pt-[10px] tw-text-center tw-text-[15px] notebook:tw-text-[10px]"
                        >
                          {post.acf.descricao}
                        </p>
                      </a>
                      {!!post.acf.arquivo &&
                        <a
                          href={post.acf?.arquivo?.url}
                          target="_blank" rel="noreferrer"
                          className=""
                        >
                          DOWNLOAD
                        </a>
                      }
                      {!!post.acf.catalogo &&
                        <a
                          href={post.acf?.catalogo?.url}
                          target="_blank" rel="noreferrer"
                          className=""
                        >
                          CATALOGO
                        </a>
                      }
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="tw-w-[23%] tw-h-[80vh] tw-my-[30px] mobile:tw-hidden">
            <Sidebar displayInfos="column" />
          </div>
        </div>
      </section>
    </main>
  );
}
