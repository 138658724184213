import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Fragment, useState } from "react";

// Context
import { useAuth } from "../../context/auth";
import { useEvents } from "../../context/events";

//images
import Logo from "../../assets/images/colored_logo.svg";
import { HighlightsIcon } from "../../assets/icons/highlights-icon.js";
import { InformativesIcon } from "../../assets/icons/informatives-icon.js";
import { CatalogsIcon } from "../../assets/icons/catalogs-icon.js";
import { LinksIcon } from "../../assets/icons/links-icon.js";
import { MuralIcon } from "../../assets/icons/mural-icon.js";
import { SocialIcon } from "../../assets/icons/social-icon.js";
import { LogOutIcon } from "../../assets/icons/log-out-icon.js";
import { FolderIcon } from "../../assets/icons/folder-icon";

//components
import { Button } from "./Button";

export const Navigation = ({ active }) => {
  const history = useHistory();

  const { signOut } = useAuth();

  const [showNav, setShowNav] = useState(false);

  //Events context
  const { showModal } = useEvents();
  return (
    <div className="tw-relative" id="menu-nav">
      <div
        className={`hamburguer  ${showNav ? "show-menu" : ""}`}
        onClick={() => setShowNav(!showNav)}
      >
        <div className="line" id="line1"></div>
        <div className="line" id="line2"></div>
        <div className="line" id="line3"></div>
        <span>Fechar</span>
      </div>
      <div
        id="navigation-side-bar"
        className={`tw-w-[100%] tw-px-[20px] tw-py-[20px] tw-flex tw-flex-col tw-justify-center notebook:tw-py-[10px] mobile:tw-fixed mobile:tw-top-0  mobile:tw-w-[200px] mobile:tw-z-[100] mobile:tw-bg-white mobile:tw-h-[100vh] ${
          showNav ? "mobile:tw-left-0" : "mobile:tw-left-[-200px]"
        }`}
      >
        <img
          src={Logo}
          alt="GMAD"
          className="tw-w-[120px] tw-h-[55px] desktop:tw-w-[135px] notebook:tw-w-[100px]"
        />
        <div className="tw-mt-[16px] desktop:tw-mt-[5px]">
          <span className="tw-text-[25px] tw-font-light tw-text-darkGreen tw-leading-[25px] desktop:tw-text-[22px] notebook:tw-text-[20px] notebook:tw-leading-[20px]">
            Olá,
          </span>
          <h1 className="tw-text-[30px] tw-text-darkGreen tw-leading-[30px] tw-mb-0 desktop:tw-text-[32px] desktop:tw-leading-[32px] notebook:tw-text-[25px] notebook:tw-leading-[25px]">
            Tudo bem?
          </h1>
        </div>
        <div className="tw-mt-[15px] desktop:tw-mt-[10px] desktop:tw-mb-[5px]">
          <p className="tw-text-[12px] tw-text-alternativeGreen tw-mb-0 tw-font-light tw-leading-[16px] notebook:tw-text-[13px] notebook:tw-leading-[13px]">
            Bem vindo a sua área do colaborador.
          </p>
        </div>
        <nav>
          <Button
            content={"Destaques"}
            identifier={`btn-component${
              active == "highlights" ? "-active" : ""
            }`}
            leftElement={<HighlightsIcon />}
            onClick={() => history.push("/highlights")}
          />
          <Button
            content={"Links Úteis"}
            identifier={`btn-component${active == "links" ? "-active" : ""}`}
            leftElement={<LinksIcon />}
            onClick={() => history.push("/links")}
          />
          <Button
            content={"Canais de Grupo"}
            identifier={`btn-component${active == "social" ? "-active" : ""}`}
            leftElement={<SocialIcon />}
            onClick={() => history.push("/social")}
          />
          <Button
            content={"Arquivos Úteis"}
            identifier={`btn-component${active == "files" ? "-active" : ""}`}
            leftElement={<FolderIcon />}
            onClick={() => history.push("/files")}
          />
          <Button
            content={"Informativos"}
            identifier={`btn-component${
              active == "informatives" ? "-active" : ""
            }`}
            leftElement={<InformativesIcon />}
            onClick={() => history.push("/informatives")}
          />
          <Button
            content={"Catálogos"}
            identifier={`btn-component${active == "catalogs" ? "-active" : ""}`}
            leftElement={<CatalogsIcon />}
            onClick={() => history.push("/catalogs")}
          />

          <Button
            content={"Calendário"}
            identifier={`btn-component${active == "mural" ? "-active" : ""}`}
            leftElement={<MuralIcon />}
            onClick={showModal}
          />

          <Button
            content={"Log Out"}
            identifier={`btn-component`}
            leftElement={<LogOutIcon />}
            onClick={() => signOut()}
          />
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
